.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.05);
}

.title {
    font-family: Poppins-Medium;
    font-weight: 400;
    line-height: 1.5rem;
    color: #2B303A;
    font-size: 1.025rem;
}

.marketBtn {
    display: flex !important;
    align-items: flex-start !important;
    gap: 8px !important;
}

.marketBtn span:nth-child(2) {
    padding: 0 !important;
}

.optionInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.marketItem {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 1rem;
    font-family: Poppins-Medium;
    font-weight: 400;
    line-height: 1.5rem;
    color: #2B303A;
}

.marketIcon {
    width: 30px;
    height: 30px;
}