.methods {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 1rem !important;
    flex-wrap: wrap !important;
    margin: 2rem auto 0;
}


.method > span:nth-child(2) {
    display: flex;
    align-items: center;
    gap: .2rem;
    transition: .3s all ease-in-out;
    font-family: Poppins-Medium;
}

.method:hover > span:nth-child(2) {
    color: #0C4CFC;
}