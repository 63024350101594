.welcomeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /*background: rgb(153, 216, 246);*/
    /*background: linear-gradient(90deg, rgba(153, 216, 246, 1) 0%, rgba(96, 186, 228, 1) 46%, rgba(198, 226, 240, 1) 100%);*/
    /*background-size: 400% 400%;*/
    /*animation: gradient 7s ease infinite;*/
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.logoContainer {
    position: relative;
    transform: translateY(-7rem);
}

.logo {
    width: 103px;
    height: 46px;
    /*border-radius: 50%;*/
    overflow: hidden;
}

.logo img {
    width: 100%;
    height: 100%;
}

.loader {
    position: absolute;
    /*top: 50%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
    /*width: 100px;*/
    /*height: 100px;*/
    top: -61px;
    left: -26px;
    /* transform: translate(-20%, -33%); */
    width: 160px;
    height: 160px;
    border: 3px solid #3e9dd6;
    border-radius: 50%;
    animation: spin 10s ease-in-out infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
        border-bottom: solid 3px transparent;
        border-top: solid 3px transparent;
    }
    50% {
        transform: rotate(1800deg);
        border: 3px solid white;
        border-left: solid 3px transparent;
        border-right: solid 3px transparent;
    }
    100% {
        transform: rotate(0deg);
        border-bottom: solid 3px transparent;
        border-top: solid 3px transparent;
    }
}

.welcomeText {
    margin-top: 5rem; /* Adjust the spacing between the logo and the text */
    text-align: center
}

.welcomeText p {
    font-size: 24px; /* Adjust the font size of the text */
    font-weight: 600;
}