@import "../../variables"
@import "../../mixins"
.container
  width: 100%
  display: flex
  position: relative
  min-height: 100vh


  .mainAndHeaderContainer
    width: 100%

    .main
      width: 100%
      padding: 30px 16px
      max-height: calc(100vh - 60px)
      overflow-y: auto
      max-width: 1440px
      margin: 0 auto

      &::-webkit-scrollbar
        display: none

    .insightMain
      width: 100%
      max-height: 100vh
      overflow-y: auto
      max-width: 100%
      margin: 0 auto
      z-index: -1


.loadingContainer
  width: 100%
  height: 100vh
  display: none
  position: fixed
  top: 0
  left: 0

  > img
    width: 70px
    height: 70px

.showLoading
  @include fullFlex
  background-color: rgba(187, 222, 248, 0.34)
  z-index: 1000

.switchSideBar
  width: 100%
  max-width: 230px
  position: fixed
  top: 0
  left: 0
  padding: 12px 16px 0
  //padding-top: 12px
  margin: 0 auto
  box-shadow: 5px 0 5px rgba(255, 255, 255, 1)
  @include fontNormal

.errorWrapper
  width: 100%
  height: 100vh
  display: flex
  align-items: center
  flex-direction: column
  gap: 50px

  > h3
    @include fontNormal
    font-size: 20px
    margin-top: 200px

  > button
    color: white !important
    font-weight: 700
    background-color: #547ef1 !important
    @include fullFlex
    gap: 8px
    font-size: 18px
    height: 48px
    width: 150px

    &:hover
      background-color: white !important
      color: #1b4aef !important

@media (max-width: 1200px)
  .main
    margin-bottom: 4.5rem !important

@media (max-width: 480px)
