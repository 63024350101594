.container {
    display: flex;
    align-items: center;
    gap: .5rem;
    cursor: pointer;
}

.attentionIcon {
    animation: pulse 1.5s infinite;
}

.text {
    color: #0C4CFC;
    text-decoration: underline;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}
