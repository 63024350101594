.list {
    margin-bottom: 0;
    width: 180px;
    border-right: 1px solid #d8defa;
    padding-right: .3rem;
    flex: 1;
}

.categoryItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5rem .5rem;
    transition: .3s all ease-in-out;
    border-radius: .4rem;
    cursor: pointer;
}

.categoryItem:hover {
    background-color: #c5dcfa;
}

.selectedItem {
    background-color: #c5dcfa;
}

/*.categoryItem:not(:last-child) {*/
/*    border-bottom: 1px solid #E4E0E1;*/
/*}*/

.categoryItem img {
    width: 15px;
    height: 15px;
}

.categoryName {
    display: flex;
    align-items: center;
    gap: .2rem;
    font-size: 14px;
}

.menu {
    display: none !important;
}

@media (max-width: 768px) {
    /*.list {*/
    /*    display: flex;*/
    /*    border-bottom: 1px solid #d8defa;*/
    /*    border-right: none;*/
    /*    padding-bottom: .3rem;*/
    /*    padding-right: 0;*/
    /*    width: 100%;*/
    /*}*/
    .list {
        display: none;
    }

    .menu {
        display: flex !important;
        justify-content: space-between;
        align-items: center;
    }

    :global(.ant-menu-horizontal) {
        line-height: 28px;
    }

    :global(.ant-menu-sub) li {
        display: flex;
    }
}