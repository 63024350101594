.container {
    display: grid;
    /* flex-direction: column; */
    /*justify-content: space-evenly;*/
    /*align-items: center;*/
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-bottom: 1rem;
}

.textBox {
    display: flex;
    align-items: center;
    /*justify-content: center;*/
    flex-direction: column;
    background-color: #c5dcfa;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05),
    5px 0 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05);
    flex: 1;
}

/*.textBox > * {*/
/*    margin-bottom: 0 !important;*/
/*}*/

.textBoxTitle {
    font-weight: 400;
    margin-right: 0.5rem;
    color: #2b303a;
    font-family: Poppins-Medium;
    font-size: 16px;
}

.textBoxInfo, .plansList {
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
}

.info {
    font-size: 1.1rem;
    display: flex;
    align-items: center;
}

.subscriptionList {
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    /*gap: .5rem;*/
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    margin: auto;
}

.plansList {
    flex-direction: column;
    gap: .1rem;
    align-items: flex-start;
    /*margin-bottom: 0;*/
    margin: auto;
}

.textBoxInfoTitle {
    margin-left: 0.3rem;
    font-size: 14px;
    margin-bottom: 0;
    /*text-align: center;*/
}

.morePlan {
    margin-left: .5rem;
    font-size: 13px;
    align-self: flex-end;
    display: flex;
    align-items: center;
    gap: .2rem;
    font-weight: 500;
    color: #0C4CFC;
    cursor: pointer;
}

.loader,
.message {
    margin-bottom: 0.5rem;
}

@media (min-width: 1500px) {
    .textBoxTitle,
    .textBoxInfoTitle {
        font-size: 1.2rem;
    }
}

@media (min-width: 1260px) and (max-width: 1500px) {
    .textBoxTitle,
    .textBoxInfoTitle {
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    .textBox {
        padding: 12px;
    }
}

@media (max-width: 620px) {
    .container {
        grid-template-columns: repeat(2, 1fr);
    }

    .textBox:last-child {
        grid-column: span 2;
    }
}

@media (max-width: 468px) {
    .container {
        gap: 14px;
    }
}
