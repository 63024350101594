
.container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px;
}

.title {
    font-family: Poppins-Medium;
    font-weight: 400;
    line-height: 1.5rem;
    color: #2B303A;
    font-size: 1.025rem;
}

.badge sup {
    top: -5px !important;
    background-color: #4b82e6;
    left: 34px !important;
    width: 85px !important;
    padding: 0.2rem !important;
    height: fit-content !important;
    border-radius: 0.7rem !important;
}

.marketBtn {
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
    position: relative;
}

.marketBtn span:nth-child(2) {
    padding: 0 !important;
}

.marketItem {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 1rem;
    font-family: Poppins-Medium;
    font-weight: 400;
    line-height: 1.5rem;
    color: #2B303A;
}

.marketIcon {
    width: 30px;
    height: 30px;
}