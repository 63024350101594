.container {
    display: flex;
}

.content {
    width: 100%;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05),
    5px 0 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 30px 28px 28px 28px;
    max-height: 550px;
    overflow-y: scroll;
}

.content::-webkit-scrollbar-track {
    background-color: transparent;
}


.header {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.secContent {
    display: flex;
    flex-direction: column;
}

.totalItems {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.total {
    display: flex;
    flex-direction: column;
}

.totalTitle {
    display: block;
    margin-bottom: .5rem;
    padding-bottom: .5rem;
    border-bottom: 1px dashed #666666;
    width: fit-content;
}

.totalValue {
    font-size: 1rem;
    color: black;
}

.listItem {
    padding: .5rem;
}

.listItem:not(:last-child) {
    margin-bottom: .5rem;
    border-bottom: 1px solid lightgray;
}

.listItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.listItem a {
    color: #0c4cfc;
    transition: .4s all;
}

.listItem a:hover img {
    animation: shake 0.2s;
    animation-iteration-count: 1;
}

.link {
    align-self: flex-start;
    cursor: pointer;
    background-color: #c5dcfa;
    padding: 0.3rem 1rem;
    border-radius: 1rem;
    transition: 0.3s all;
    font-size: 15px;
    margin-top: auto;
}

@keyframes shake {
    0% {
        transform: rotate(0);
    }

    30% {
        transform: rotate(-20deg);
    }

    60% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(20deg);
    }


}

.message {
    display: flex;
    justify-content: center;
}
