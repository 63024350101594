.container {
    display: flex;
}

.content {
    width: 100%;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05),
    5px 0 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 30px 28px 28px 28px;
    display: flex;
    flex-direction: column;
    max-height: 550px;
    overflow-y: scroll;
}

.content::-webkit-scrollbar-track {
    background-color: transparent;
}

.header {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pricingBtn {
    display: block;
    width: fit-content;
    margin: 1rem auto;
    background-color: #1890ff;
    padding: 0.3rem 1rem;
    color: white;
    border-radius: 0.4rem;
    font-weight: 600;
    transition: 0.4s all;
}

.pricingBtn:hover {
    color: white;
    background-color: #095ead;
}

.link {
    align-self: flex-start;
    cursor: pointer;
    background-color: #c5dcfa;
    padding: 0.3rem 1rem;
    border-radius: 1rem;
    transition: 0.3s all;
    color: rgba(0, 0, 0, 0.86);
    display: inline-block;
    margin-top: auto;
}

.link:hover {
    color: rgba(13, 100, 161, 0.85);
}
