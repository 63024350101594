@import "../../variables"
@import "../../mixins"
.tableContent
  @include fontNormal
  font-size: .9rem
  //display: flex
  //align-items: center
  img
    width: 20px
    height: 20px
    border-radius: 100%
    margin-right: .3rem


.assetSuffix
  color: grey
  font-size: 12px
  margin-left: .2rem

.table
  margin-bottom: 20px

  th
    color: blue !important
    font-size: 1rem !important
    background-color: rgba(150, 171, 234, 0.25) !important

.green
  color: $green

.red
  color: $Lipstick

.buy
  //background-color: #0C4CFC
  border: 1px solid #70AF85
  //color: #0C4CFC
  background-color: #70AF85
  color: white

  padding: 4px 8px
  border-radius: .5rem
  font-size: 12px
  @include fullFlex
  width: 65px
  margin: 0 auto
  transition: .3s all
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 10px 0px

  &:hover
    //background-color: #0C4CFC
    //color: white
    color: #70AF85
    background-color: white

.sell
  border: 1px solid #EF4B4B
  background-color: #EF4B4B
  color: white
  //color: red
  padding: 4px 8px
  border-radius: .5rem
  font-size: 12px
  @include fullFlex
  width: 65px
  margin: 0 auto
  transition: .3s all
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 10px 0px

  &:hover
    //background-color: red
    //color: white
    color: #EF4B4B
    background-color: white

.holdBuy
  //background-color: #f1c40f
  //color: white
  //border: 1px solid #f1c40f
  border: 1px solid #82A0D8
  color: white
  background-color: #82A0D8
  //color: #f1c40f
  padding: 4px 8px
  border-radius: .5rem
  font-size: 12px
  @include fullFlex
  width: 65px
  margin: 0 auto
  transition: .3s all
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 10px 0px

  &:hover
    //color: white
    color: #82A0D8
    //background-color: #f1c40f
    background-color: white

.hideContent
  border: 1px solid #bdc3c7
  color: white
  background-color: #bdc3c7
  width: 50px
  border-radius: .5rem
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 10px 0px

.buyNow
  display: flex !important
  justify-content: center !important
  align-items: center !important
  margin: auto !important
  font-size: 16px !important
  color: white !important
  font-weight: 700
  background-color: #547ef1 !important

  &:hover
    background-color: white !important
    color: #1b4aef !important

  > svg
    stroke-width: 1px

.wrapper
  width: 100%

  > div
    &:nth-of-type(1)
      width: 100%
      display: flex
      flex-direction: row-reverse
      justify-content: space-between
      margin-top: 24px

      > p
        min-width: max-content

        span
          color: black
          margin-right: 8px

      > div
        width: 100%
        display: flex
        flex-direction: column

        > p
          span
            color: black
            margin-right: 8px


    &:nth-of-type(2)
      width: 100%
      display: flex
      gap: 24px
      margin-top: 16px

      > span
        width: 100%

        @include fullFlex
        height: 48px
        border-radius: 8px
        box-shadow: rgba(173, 188, 200, 60%) 0 2px 66px
        transition: all .2s linear
        cursor: pointer
        font-weight: 600
        background: transparent

        &:hover
          box-shadow: rgba(173, 188, 200, 80%) 0 2px 66px
          transform: scale(1.05)


      .active
        background: rgb(176, 172, 230)
        color: white


    &:nth-of-type(3)
      width: 100%
      display: flex
      gap: 24px
      margin-top: 36px
      justify-content: center

      input
        border-radius: 8px
        height: 48px

      > div
        display: flex
        flex-direction: column
        gap: 8px
        width: 100%

    &:nth-of-type(4)
      margin-top: 36px
      width: 100%
      display: flex
      gap: 24px

      button
        width: 100%
        @include fullFlex
        gap: 16px

        svg
          font-size: 18px

        &:nth-of-type(1)
          background-color: #0C4CFC !important
          height: 48px !important
          @include fontNormal
          color: white !important

        &:nth-of-type(2)
          background-color: red !important
          height: 48px !important
          @include fontNormal
          color: white !important


.disabledTrackingBtn
  width: 100%
  display: flex
  align-items: center
  justify-content: flex-end
  margin-top: 24px

  button
    width: 256px
    @include fullFlex
    gap: 16px

    svg
      font-size: 18px

    &:nth-of-type(1)
      background-color: #0C4CFC !important
      height: 48px !important
      @include fontNormal
      color: white !important

.trackedSignalsWrapper
  > h3
    @include fontNormal
    font-size: 18px
    margin-bottom: 24px

  > p
    @include fontNormal
    color: #6a6868
    margin-bottom: 36px

.toggleBtn
  background-color: rgba(255, 255, 255, 0.44)
  width: 28px
  height: 28px
  border-radius: 50%
  display: flex
  justify-content: center
  align-items: center
  color: rgb(11, 18, 95)
  font-weight: 700
  cursor: pointer

.tableOptions
  display: flex
  justify-content: space-between
  align-items: center
  margin: 1rem 0
  position: relative

.tableButtons
  align-self: flex-end
  margin-left: auto

.refreshBtn
  background-color: #FFC436 !important
  padding: .3rem .7rem !important
  border-radius: .4rem !important
  font-size: 12px !important
  display: flex !important
  align-items: center !important
  justify-content: center !important
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important
  //position: fixed !important
  //top: 29.05%
  //right: 388px

  &:hover
    color: #0d0d0d !important
    background-color: #E9B824 !important
    border-color: #E9B824 !important

  &:active
    color: #0d0d0d !important
    background-color: #E9B824 !important
    border-color: #E9B824 !important

  &:focus
    color: #0d0d0d !important
    background-color: #E9B824 !important
    border-color: #E9B824 !important

.copyBtn
  background-color: #547ef1 !important
  border: 1px solid #547ef1 !important
  color: white !important
  padding: .2rem .5rem !important
  border-radius: .3rem !important
  height: auto !important
  cursor: pointer
  transition: .3s all
  font-family: Poppins-Regular !important
  font-size: 12px !important

  &:hover
    background-color: transparent !important
    color: #547ef1 !important

.headerTitle
  display: flex
  justify-content: center
  align-items: center

.refreshPriceStatus
  text-align: center
  display: flex
  align-items: center
  margin: 1rem auto
  justify-content: center
  gap: .3rem

.expandIcon
  color: #0C4CFC
  cursor: pointer
  transition: .3s all
  border: 1px solid transparent

  &:hover
    color: #082a87
    transform: scale(1.05)

.countdown
  display: flex
  align-items: center

.resetHistory
  background-color: #0C4CFC !important
  color: white !important
  @include fullFlex
  gap: .3rem !important
  //height: 36px !important

  path
    stroke: white

  &:hover
    color: #0C4CFC !important
    border-color: #0C4CFC !important
    background-color: white !important

    path
      stroke: #0C4CFC !important

  &:active
    color: #0C4CFC !important
    border-color: #0C4CFC !important
    background-color: white !important

    path
      stroke: #0C4CFC !important

  &:focus
    color: #0C4CFC !important
    border-color: #0C4CFC !important
    background-color: white !important

    path
      stroke: #0C4CFC !important

  > span
    display: flex
    align-items: center
    gap: 8px

.coinIcon
  width: 20px
  height: 20px
  border-radius: 100%
  margin-right: .5rem

.balances
  padding-left: .2rem
  font-family: Poppins-Regular

  > span
    font-weight: 600

.pnlInfo
  display: flex
  justify-content: center
  align-items: center
  gap: .1rem


.positive
  color: green


.negative
  color: grey

.coin
  cursor: pointer

.insightTableCol
  display: flex
  align-items: center
  justify-content: space-between
  position: relative

//@media (min-width: 1490px)
//  .refreshBtn
//    top: 26.5% !important
//    right: 412px !important
//
//@media (min-width: 1200px)
//  .refreshBtn
//    right: 407px

@media (max-width: 768px)
  .refreshPriceStatus
    display: none

  .desktopVersion
    display: none

@media (max-width: 600px)
  .tableContent
    font-size: 12px !important

    //img
    //  width: 20px !important
    //  height: 20px !important
    //  margin-right: .3rem
  .table
    th
      font-size: 14px !important
//.buy
//font-size: 14px
//width: 50px
//.sell
//font-size: 14px
//width: 50px

@media (max-width: 768px)
  .refreshBtn
    display: none !important

@media (max-width: 480px)
  .buyNow
    font-size: 12px !important
    padding: 8px 12px !important
  .holdBuy
    width: 50px
    font-size: 10px
  .buy
    width: 50px
    font-size: 10px
  .sell
    width: 50px
    font-size: 10px
  .hideContent
    width: 35px
    padding: .3rem
    font-size: 10px

    svg
      width: 18px
      height: 18px

  .pnlInfo
    flex-direction: column

@media (max-width: 480px)
  .assetSuffix
    font-size: 10px