.modal :global(.ant-modal-close) {
    top: 0;
    right: 0;
}

.modal :global(.ant-modal-close):hover {
    background-color: transparent;
}

.loader {
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.container {
    display: flex;
    flex-direction: column;
    min-height: 300px;
}

.body {
    display: flex;
}

@media (min-width: 800px) {
    /*.modal :global(.ant-modal-body) {*/
    /*    height: 600px;*/
    /*}*/
    .container {
        min-height: 100%;
    }
}

@media (max-width: 768px) {
    .body {
        flex-direction: column;
    }
}