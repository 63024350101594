.container {
    /*border: 1px solid #ecf0f1;*/
    border-radius: .3rem;
    padding: 1rem;
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.container li {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: .3rem;
    /*border-bottom: 1px solid #ecf0f1;*/
    padding: .3rem .5rem;
}

.title {
    font-weight: 500;
}