@import "../../variables"
@import "../../mixins"

.stripeInputs
  width: 100%


.form
  width: 100%
  display: flex
  flex-direction: column
  min-height: 120px
  padding-top: 8px
  gap: 40px

  > div
    &:nth-of-type(1)
      width: 100%
      padding: 18px 8px
      border-radius: 8px
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05), 5px 0 5px rgba(0, 0, 0, 0.05), -5px 5px 5px rgba(0, 0, 0, 0.05)

    &:nth-of-type(2)
      width: 100%
      @include fullFlex

  button
    width: 100%
    padding: 8px
    background-color: $Contessa
    border-radius: 8px
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05)
    @include fontNormal
    color: $white
    font-weight: 700
    @include fullFlex

.plans
  width: 100%
  display: flex
  gap: 24px
  margin-bottom: 40px


  > div
    @include fontNormal
    display: flex
    width: 100%
    flex-direction: column
    gap: 24px
    align-items: center
    padding: 16px
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05), 0 -5px 5px rgba(0, 0, 0, 0.05), 5px 0 5px rgba(0, 0, 0, 0.05), -5px 0 5px rgba(0, 0, 0, 0.05)
    border-radius: 8px
    @include fontNormal
    justify-content: center
    transition: all .2s linear
    cursor: pointer

    &:hover
      transform: scale(1.05)
      background-color: rgba(6, 60, 216, 0.23)

    > div

      &:first-child
        @include fontNormal
        display: flex
        width: 100%
        flex-direction: column
        gap: 24px
        align-items: center
        padding: 16px
        border-radius: 8px
        @include fontNormal
        padding: 16px
        transition: all .2s linear

        > span
          &:nth-of-type(1)
            font-size: 20px
            color: #0C4CFC
            display: flex
            align-items: center

            svg
              font-size: 30px
              margin-right: 8px

          &:nth-of-type(2)
            font-size: 25px

            > span
              font-size: 16px
              color: $Mortar


.active
  background-color: rgba(6, 60, 216, 0.28) !important

.button
  width: 100%
  padding: 8px
  background-color: $Contessa
  border: 1px solid #754a7f
  border-radius: 8px
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05)
  @include fontNormal
  color: $white
  font-weight: 700
  @include fullFlex
  transition: .3s all ease-in-out

  &:hover
    background-color: white
    color: #754a7f

@media (max-width: 768px)
  .plans
    > div
      padding: 0

      > div
        &:first-child
          > span
            &:nth-of-type(1)
              font-size: 18px

            &:nth-of-type(2)
              font-size: 20px

@media (max-width: 480px)
  .plans
    > div
      > div
        &:first-child
          gap: 8px

          > span
            &:nth-of-type(1)
              font-size: 16px

              svg
                font-size: 25px

            &:nth-of-type(2)
              font-size: 18px               

