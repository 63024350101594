.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    padding: 1rem 1rem 0 1rem;
    flex: 3;
    min-height: 300px;
}

.emptyBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.emptyBox p {
    font-size: 14px;
    text-align: center;
}

@media (max-width: 768px) {
    .container {
        display: unset;
        position: relative;
    }
}