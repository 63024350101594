.container {
    margin: .5rem;
    border: 1px solid #f0f0f0;
    border-radius: 1rem;
    padding: 1rem;
    background-color: white;
}

.dataList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin: 0;
    padding: 0;
}

.dataList li {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: .5rem;
}

.label {
    font-family: Poppins-Medium;
    font-weight: 400;
    line-height: 1.5rem;
    color: #2B303A;
    font-size: 0.9rem;
}

.dataList li span:nth-child(2) {
    font-size: 15px;
    font-weight: 500;
    color: #747d8c;
}

.red {
    color: #AA0347 !important;
}

.green {
    color: #27AE60 !important;
}

/*@media (min-width: 768px) {*/
/*    .marketType {*/
/*        display: none !important;*/
/*    }*/
/*}*/

/*@media (min-width: 576px) {*/
/*    .oneDay, .oneWeekValue, .oneHour {*/
/*        display: none !important;*/
/*    }*/
/*}*/

@media (max-width: 480px) {
    .label {
        font-size: 14px;
    }
}