@import "../variables"
@import "../mixins"

.hideMore
  //transform: translateX(-100%)
  opacity: 0
  height: 0

.showMore
  //transform: translateX(230px)
  opacity: 1
  height: 275px

.sidebar
  height: 100vh
  width: 230px
  min-width: 230px
  position: relative


  > div
    background-color: #ffffff !important
    box-shadow: 5px 0 15px rgba(0, 0, 0, .05)

  .sidebarMenu
    padding-bottom: 0 !important

    > ul
      display: flex
      flex-direction: column
      gap: 20px
      @include fontNormal
      color: #414144 !important

      > li
        svg
          color: black

        > div
          > span


            &:first-child
              background-color: white !important
              border-radius: 45px
              min-width: 40px !important
              height: 40px !important

          &:hover
            color: black !important

          &:focus
            color: black !important

          &:nth-of-type(2)
            background-color: #ecf5fb !important
            color: black !important


            > div
              //background-color:  rgba(195, 189, 244, 0.76) !important

              li

                > div
                  &:hover
                    color: black !important
                    transition: all .2s linear

      .icon
        //color:  #ffffff !important
        font-size: 24px

.insightSideBar
  transform: translateY(60px)
  height: calc(100vh - 60px)

.active
  &:before
    content: ""
    position: absolute
    top: -18px !important
    left: 0
    width: 10px
    height: 85px
    background-color: #2781d8
    border-bottom-right-radius: 8px
    border-top-right-radius: 8px
    z-index: 1000

.disabled
  span
    cursor: not-allowed !important

@media screen and (max-width: 1200px)
  .sidebar
    display: none