.container {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu li.ant-dropdown-menu-item-selected {
    color: #0d0d0d !important;
}

.menu li span {
    display: flex;
    align-items: center;
    gap: .3rem;
}

.menuItemIcon {
    width: 25px;
}

.navList {
    display: flex;
    align-items: center;
    margin: 0;
}

.navItem {
    display: flex;
    align-items: center;
    gap: .3rem;
    padding: .8rem;
    cursor: pointer;
    transition: .3s all;
    border-bottom: 1px solid transparent;
}

.navItem:hover {
    color: #2b5fec;
}

.active {
    color: #2b5fec;
    border-bottom-color: #2b5fec;
}

.separator {
    height: 20px;
    width: 2px;
    background-color: #9da0a8;
}

.navItemIcon {
    width: 20px;
}

@media (min-width: 1200px) {
    .container {
        display: none;
    }
}

@media (max-width: 530px) {
    .navItemIcon {
        display: none;
    }

    .navItem {
        padding: .5rem;
    }
}

@media (max-width: 480px) {
    .container {
        flex-direction: column-reverse;
        gap: 10px;
        align-items: flex-start;
    }

    .navList {
        align-self: flex-end;
    }

    .navItem {
        padding: .5rem .8rem !important;
    }
}