.container {
    margin: 1.5rem 0 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border: 2px solid #0C4CFC;
    border-top: none;
    border-radius: 0 0 .4rem .4rem;
}

.title {
    font-weight: 600;
}

.portfolioList {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0;
}

.portfolioItem {
    width: 120px;
    background-color: rgba(187, 221, 244, 0.68);
    padding: .6rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .4rem;
    font-weight: 500;
    cursor: pointer;
    transition: .3s all ease-in-out;
    gap: .2rem;
    position: relative;
    border: 2px solid transparent;
}

.portfolioItem:hover, .active {
    /*background-color: #0C4CFC;*/
    /*color: white;*/
    border-color: #0C4CFC;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.disable {
    opacity: .7;
    cursor: not-allowed;
}

.disable:hover {
    /*background-color: rgba(187, 221, 244, 0.68) !important;*/
    border-color: transparent !important;
    box-shadow: unset !important;
    color: unset !important;
}

.badge {
    position: absolute;
    top: -2px;
    right: -2px;
    /*background-color: #011e53;*/
    background-color: #add8e6;
    padding: 0 .2rem;
    font-size: 10px;
    border-radius: 0 .3rem 0 0;
}

.waiting {
    opacity: .7;
    cursor: progress;
}

.waiting:hover {
    border-color: transparent !important;
}
